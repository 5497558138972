<div class="p-d-flex p-flex-column">
  <div class="p-col-12 p-md-12 p-lg-8 p-mx-auto" [id]="header" style="background-color: #ffffff; padding: 0" >
    <app-header></app-header>
  </div>
  <div class="p-col-12 p-md-12 p-lg-8 p-mx-auto" style="background-color: #f5f5f5; padding: 0">
    <router-outlet></router-outlet>
  </div>
  <div class="p-col-12 p-md-12 p-lg-8 p-mx-auto" [id]="footer" style="background-color: #f5f5f5; padding: 0">
    <app-footer></app-footer>
  </div>
</div>
