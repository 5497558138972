
<div class="main-content">
  <div class="title-text p-mx-auto p-grid p-jc-center" style="margin-bottom: 20px">
    <span>Ford Search Engine</span>
  </div>
  <div class="regular-text p-mx-auto p-grid p-jc-start">
    <div class="p-col-12">
      <span STYLE="font-weight: bold">Goal: </span>
      <span>Develop a simple and easy way to allow people to understand their vehicle data</span>
    </div>
    <div class="p-col-12">
      <span STYLE="font-weight: bold">Technologies used: </span>
      <span>Java</span>
    </div>
    <div class="p-col-12">
      <span STYLE="font-weight: bold">When: </span>
      <span>Oct 2019</span>
    </div>
    <div class="p-col-12">
      <span STYLE="font-weight: bold">Estimated Cost Savings: </span>
      <span>$3-5 million</span>
    </div>
    <br>
    <div class="title-text p-col-12" style="border: none; width: fit-content">
      <span>Overview</span>
    </div>
    <div class="p-col-12 regular-text">
      <span>
        This is a tool that I developed about a month into my first co-op term at Ford. For the first couple of weeks
        I was just following my boss to meetings to try and get caught up on what everyone was working on. Through
        listening to what people were struggling on, I came to the conclusion that a tool that let people search for
        keywords to find what possible data could come off of vehicles was needed. The data was always available to
        these engineers and other employees but most of them had no idea that they could get such data. This tool gives
        the user the ability to quickly search through hundreds of thousands of possible data per program line and
        figure out what they need to help improve what they are working on.
      </span>
    </div>
  </div>
</div>
