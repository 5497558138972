<div class="footer-content p-grid">
  <div class="p-col-6 p-my-auto">
    <span class="small-text" style="font-size: 11px">Designed and Built by Zachary Warunek</span>
    <br>
    <span class="small-text" style="font-size: 11px">Made with </span>
    <a href="https://www.primefaces.org/primeng/showcase/#/setup" target="_blank" class="small-text" style="color: #474747; cursor: pointer; font-size: 11px; text-decoration: none; font-weight: bold"> PRIMENG</a>
  </div>
  <div class="p-col-6 p-d-flex p-jc-end" style="padding-right: 0">
    <a href="https://github.com/zwarunek" target="_blank">
      <fa-icon [icon]="githubIcon" size="3x" class="fa-footer p-ml-2"></fa-icon>
    </a>
    <a href="https://www.linkedin.com/in/zachary-warunek/" target="_blank">
      <fa-icon [icon]="linkedinIcon" size="3x" class="fa-footer p-ml-2"></fa-icon>
    </a>
  </div>
</div>
