<div class="main-content">
  <div class="title-text p-mx-auto p-grid p-jc-center" style="margin-bottom: 20px">
    <span>Employment</span>
  </div>
  <div class="small-text" style="padding-top: 20px">
    <p-timeline [value]="employmentTimeline"><!--align="alternate"-->>
      <ng-template pTemplate="content" let-event>
        <p-card [header]="event.employer">
          <div class="p-col-12 p-grid p-jc-start" *ngIf="event.position !== ''">
            <span style="font-weight: bold">Position:</span>&nbsp;
            <span style="font-weight: normal"> {{event.position}}</span>

          </div>
          <div class="p-col-12 p-grid p-jc-start" *ngIf="event.date !== ''">
            <span style="font-weight: bold">Date:</span>&nbsp;
            <span style="font-weight: normal"> {{event.date}}</span>

          </div>
          <div class="p-col-12 p-grid p-jc-start" *ngIf="event.type !== ''">
            <span style="font-weight: bold">Type:</span>&nbsp;
            <span style="font-weight: normal"> {{event.type}}</span>

          </div>
          <div class="p-col-12 p-grid p-jc-start" *ngIf="event.info !== ''">
            <span style="font-weight: bold">Info:</span>&nbsp;
            <span style="font-weight: normal"> {{event.info}}</span>
          </div>
        </p-card>
        <br>
        <br>
      </ng-template>
      <ng-template pTemplate="opposite" let-event >
      <div *ngIf="!mobile" class="p-jc-end p-d-flex">
        <div style="width: fit-content">
          <p-card>
            <img src="{{event.img}}" width="150">
          </p-card>
        </div>
      </div>
      </ng-template>
    </p-timeline>
  </div>
</div>
