import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fordsearchengine',
  templateUrl: './fordsearchengine.component.html',
  styleUrls: ['./fordsearchengine.component.css']
})
export class FordsearchengineComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
