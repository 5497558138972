<div class="p-mx-auto p-grid p-jc-center main-content">
  <div class="p-col-12" style="padding:  0 0 0 0">
    <div class="title-text p-mx-auto" style="margin-bottom: 30px">
      <span>Projects</span>
    </div>
  </div>
    <p-card routerLink="pathfinding" header="Pathfinding Visualizer" subheader="A tool for visualizing various pathfinding algorithms" class=" project-card">
      <ng-template pTemplate="header">
        <img alt="Card" src="../../assets/images/projects/PathfindingVisualizerThumbnail.jpg">
      </ng-template>
    </p-card>
  <p-card routerLink="portfolio" header="Portfolio Website" subheader="The website that you are on" class="project-card">
    <ng-template pTemplate="header">
      <img alt="Card" src="../../assets/images/projects/portfolio.PNG">
    </ng-template>
  </p-card>
    <p-card routerLink="timemachine" header="Time Machine" subheader="Minecraft Plugin" class="project-card">
      <ng-template pTemplate="header">
        <img alt="Card" src="../../assets/images/projects/TimeMachine.png">
      </ng-template>
    </p-card>
    <p-card routerLink="fordsearchengine" header="Ford Search Engine" subheader="Search Engine for Engineering Specs" class=" project-card">
      <ng-template pTemplate="header">
        <img alt="Card" src="../../assets/images/projects/fordsearchenginenotlogo.png">
      </ng-template>
    </p-card>
    <p-card routerLink="flappybirdai" header="Flappy Bird AI" subheader="Flappy Bird Game controlled by a Neural Net" class=" project-card">
      <ng-template pTemplate="header">
        <img alt="Card" src="../../assets/images/projects/flappybird.png">
      </ng-template>
    </p-card>
</div>
