
<div class="main-content">
  <div class="title-text p-mx-auto p-grid p-jc-center" style="margin-bottom: 20px">
    <span>Pathfinding Visualizer</span>
  </div>
  <div class="regular-text p-mx-auto p-grid p-jc-start">
    <div class="p-col-12">
      <span STYLE="font-weight: bold">Goal: </span>
      <span>Build a website for visualizing various pathfinding algorithms</span>
    </div>
    <div class="p-col-12">
      <span STYLE="font-weight: bold">Technologies used: </span>
      <span>Angular, </span>
      <span>Typescript, </span>
      <span>HTML, </span>
      <span>CSS </span>
    </div>
    <div class="p-col-12">
      <span STYLE="font-weight: bold">When: </span>
      <span>June 2021 - Present</span>
    </div>
    <div class="p-col-12">
      <span STYLE="font-weight: bold">Helpful Links: </span>
      <a class="link" style="text-decoration: underline" (click)="goToLink('https://Pathfindingvisualizer.net')">Pathfindingvisualizer.net</a>,
      <a class="link" style="text-decoration: underline" (click)="goToLink('https://github.com/zwarunek/TimeMachine')">GitHub</a>
    </div>
    <br>
    <div class="title-text p-col-12" style="border: none; width: fit-content">
      <span>Overview</span>
    </div>
    <div class="p-col-12 regular-text">
      <span>
        PathfindingVisualizer.net is a website that I created in June of 2021. The goal was to challenge myself with a new
        project where I can build a new website and learn the details of many pathfinding algorithms and actually apply
        the knowledge.

      </span>
    </div>
    <div class="p-col-12 regular-text">
      <span>
        The key takeaways that I got from this website is learning how each algorithm functions. When implementing each algorithm,
        I tried to use as little online resources as possible, except an overview of each algorithm, to really challenge myself.
        This tactic forced me to learn each one through and through to properly implement and I think will be very helpful
        in future projects and employment.
      </span>
    </div>
    <div class="p-col-12 regular-text">
      <span>
        Some of the challenges that I faced of building the website was coming up with a function that will dynamically update the
        graph when the user creates or deletes walls. The biggest challenge was creating an algorithm that recursively creates a random maze with
        only one path to the target. This algorithm basically creates a row of walls down the middle with one opening, creating
        two new sections, then generating a number of columns in each section with one opening as well. Then it repeats with
        more rows for each sections until it is done.
      </span>
    </div>
  </div>
</div>
