
<div class="main-content">
  <div class="title-text p-mx-auto p-grid p-jc-center" style="margin-bottom: 20px">
    <span>Personal Portfolio</span>
  </div>
  <div class="regular-text p-mx-auto p-grid p-jc-start">
    <div class="p-col-12">
      <span STYLE="font-weight: bold">Goal: </span>
      <span>Build a website the displays my work experience, info about me, and personal projects</span>
    </div>
    <div class="p-col-12">
      <span STYLE="font-weight: bold">Technologies used: </span>
      <span>Angular, </span>
      <span>AWS, </span>
      <span>Typescript, </span>
      <span>HTML, </span>
      <span>CSS </span>
    </div>
    <div class="p-col-12">
      <span STYLE="font-weight: bold">When: </span>
      <span>Oct 2020</span>
    </div>
    <br>
    <div class="title-text p-col-12" style="border: none; width: fit-content">
      <span>Overview</span>
    </div>
    <div class="p-col-12 regular-text">
      <span>
        I built this website from scratch using Angular. The purpose is to display all of my work experience, projects, and much more.
        This was a great introduction into building websites from the ground up. I had some prior experience with Angular from
        a previous co-op at Ford where I helped maintain and further develop an internal website.
        This gave me initial experience working with cloud computing using AWS to host this site. I also learned many things
        design UI and UX principles and structuring a website. I also gained insight into Search Engine Optimization to get
        it to show up on the first page of Google.
      </span>
    </div>
  </div>
</div>
