import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-flappybird',
  templateUrl: './flappybird.component.html',
  styleUrls: ['./flappybird.component.css']
})
export class FlappybirdComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
