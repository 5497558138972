<div class="p-mx-auto p-grid p-jc-center main-content" style="margin-top: 90px;">
  <div style="width: 100%; display: flex; justify-content: center">
    <img src="assets/images/portrait.png" alt="" width="200px" height="200px" style="border-radius: 15px; box-shadow: #6c757d 0 0 15px;">
  </div>
  <div class="title-text" style="margin-top: 30px">
    <span>About Me</span>
  </div>
  <div class="p-mx-auto p-col-12" style="padding: 0">
    <p class="regular-text">Hello, I am Zachary Warunek and currently a Senior at Kettering University in Flint,
      Michigan with an expected graduation date of December 2022</p>
  </div>
  <div class="title-text">
    <span>Education</span>
  </div>
  <div class="p-mx-auto p-col-12" style="padding: 0">
    <p class="regular-text">I graduated from Woodhaven High School in the summer of 2019. During my time there I was
      involved in many activities. Starting my Freshman year, I played varsity tennis and varsity hockey and played both
      sports through my Senior year. I also took AP Computer Science, AP Statistics, and AP Calculus
  BC and passed each AP exam. During my Junior year, I took the SAT, achieving 1370 (740 on math). The summer that
  I graduated from Woodhaven, I started attending Kettering University pursuing a degree in Computer Science. Three months prior to high school graduation,
  I landed my first Co-op at Ford Motor Company.</p>
</div>
<div class="title-text">
  <span>Employment</span>
</div>
  <div class="p-mx-auto p-col-12" style="padding: 0">
    <p class="regular-text">I am currently employed at Ford Motor Company as a Co-op student, where I've been employed since the Fall of
      2019. During my first semester there, I worked in Connected Vehicles as a Software Engineer. I was not given many tasks
      at the beginning so I had to keep myself busy. I ended up discovering a need for a search engine for data coming
      off of vehicles, which I later pitched, developed, and brought to production in my first 3  months there (<a class="link" style="font-weight: bold" href="../projects/fordsearchengine">Ford Search Engine</a>).
      In my next two terms, I was in a new department and had the job of a full stack web developer making an internal site for
      remotely monitoring vehicles using Java and AngularJS. I am working in the Advanced Driver Assist System (ADAS) area as
      a software engineer.
    </p>
    <p class="regular-text">
      I will be working at Amazon in the summer of 2022 as a Software Development Engineer Intern on the Advertising team in Irvine, California.
    </p>
  </div>
  <div class="title-text">
    <span>Activities</span>
  </div>
  <div class="p-mx-auto p-col-12" style="padding: 0">
    <p class="regular-text">In my free time, I often find myself still coding, for example this website. I
      love to make cool and different varieties of software which there is more info about in
      <a class="link" style="font-weight: bold" href="../projects">projects</a>. I also am into 3D Printing, Machine
      Learning, and gaming. I am also a member of the Zeta Theta A chapter of Sigma Chi at Kettering.</p>
  </div>
</div>
